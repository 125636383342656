import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../components/sidebar'
import Navbar from '../../components/navbar'
import TextInput from '../../components/common/TextInput'
import { createOrganizationType } from '../../api/insert/Admin'
// Toast notification 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAllOrganizationTypes } from '../../api/get/Admin'
import { FaTrash, FaEdit } from 'react-icons/fa';
import EditOrgType from '../../components/Admin/EditOrgType'
import { removeTheOrgType } from '../../api/update/Admin'


const OrganizationsType = () => {
    const [name,setName] = useState('');
    const [orgTypeList , setOrgTypeList ] = useState([]);
    const [editOrgType , setEditOrgType] = useState(false);
    // const [error , setError] = useState('');
    // handle the organization type

    // get all org Types
    useEffect(()=>{
        getorgTypes()
    },[])
    const getorgTypes=async()=>{
        const org = await getAllOrganizationTypes();
        if(org.data){
            setOrgTypeList(org.data);
        }
    }
            
    
    const handleSelect=(e)=>{
        setName(e.target.value);
    }
    const addOrgType =async()=>{
        if(name === ''){
            // setError('Required')
            toast.error('Cannot be empty');
            getorgTypes();
        }
       else{
        const orgType = await createOrganizationType(name);
        console.log(orgType);
        if(orgType.data.success === true){
            setName('');
            toast.success('New Organization Type added Successfully');
        }
        else{
            toast.error(orgType.response.message);
        }
       }
    }
    const orgTypeData = useRef('')
    const handleOpenEditOrgType = (item)=>{
        setEditOrgType(true);
        orgTypeData.current = item;
    }

    // remove the orgType
    const handleRemoveOrgType=async(orgTypeId)=>{
        const removeOrg = await removeTheOrgType(orgTypeId);
        if(removeOrg.data){
            alert(removeOrg.data.message);
            getorgTypes();
        }
    }
  return (
    <>
    <Navbar/>
    <Sidebar/>
    <div className=' sm:ml-[240px] pt-[65px] px-2'>
        <div className=' py-10 px-5 w-10/12 flex justify-center gap-4 '>
            <div className='flex flex-col gap-1 w-full'>
                <p>Please enter the Organization Type</p>
                <div className='flex gap-3 '>
                <TextInput
                    placeholder={"Organization Type"}
                    label={"Organization Type"}
                    value={name}
                    type={"text"}
                    onChange={handleSelect}
                    name={"Organization Type"}
                    // invalidMessage={error}
                />
                <button onClick={addOrgType} className='text-white bg-blue-500 rounded-lg font-semibold py-1 px-5'>Add</button>
                </div>
                
            </div>
        
        
        </div>
        <div className=''>
            <table>
                <tr>
                    <th>Organization Type</th>
                    <th>Action</th>
                </tr>
                {orgTypeList.map((item,index)=>(
                    <tr key={index} className='px-2'>
                        <td className='px-3 py-4'>{item.name}</td>
                        <td className='flex justify-center px-3 py-4 gap-3'>
                            <button onClick={()=>{handleOpenEditOrgType(item)}} className="text-blue-700 flex items-center " >
                                <FaEdit />
                            </button>
                            <button onClick={()=>{handleRemoveOrgType(item._id)}} className="text-blue-700 flex items-center ">
                                <FaTrash />
                            </button>
                        </td>
                    </tr>
                ))}
               
            </table>
        </div>
    </div>
    <ToastContainer 
         position='top-right'
         autoClose={3000}    
      />
      <EditOrgType open={editOrgType} onClose={()=>{setEditOrgType(false)}} item={orgTypeData.current} refresh={getorgTypes} />
   </>
   
  )
}

export default OrganizationsType