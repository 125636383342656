import React, { useState, useEffect, useRef } from "react";
import TextInput from "../common/TextInput";
import { updateTheOrganizationType } from "../../api/update/Admin";

const EditOrgType = ({ open, onClose,item,refresh }) => {
const [OrganizationType , setNewOrganizationType] = useState('');
useEffect(()=>{
    if(item){
        setNewOrganizationType(item.name)
    }
},[item])
const handleDataInput =(e)=>{
    const {name,value} = e.target;
    setNewOrganizationType(value);
}

const handleTheUpdateOfOrgType=async()=>{
    console.log(item._id);
    const update = await updateTheOrganizationType(OrganizationType,item._id);
    console.log(update);
    if(update.data){
        alert(update.data.message);
        onClose();
        refresh()
    }
}
  return (
    <>
      <div
        id="static-modal"
        data-modal-backdrop="static"
        tabIndex="-1"
        aria-hidden={!open}
        className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full bg-gray-800 bg-opacity-50 md:inset-0 h-full ${
          open ? "flex" : "hidden"
        }`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow-2xl">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900">
                Update Organization Type
              </h3>
              <button
                type="button"
                className="text-gray-700 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-400 dark:hover:text-white"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            
            <div className="flex flex-col gap-2 px-4 mt-3 mb-4">
              <p className="text-lg ml-2">
                Organization Type <span className="text-red-500">*</span>
              </p>
              <TextInput
                placeholder={" Organization Type"}
                label={" Organization Type"}
                type={"text"}
                onChange={handleDataInput}
                value={OrganizationType}
               
                name={"OrganizationType"}
                invalidMessage={""}
              />
            </div>

            {/* bottom buttuns  */}
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
               onClick={handleTheUpdateOfOrgType}
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Update
              </button>
              <button
                type="button"
                className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOrgType;
