import React, { useEffect, useRef, useState } from "react";
import { getContactOrgId } from "../../api/get/getContact";
import { updateContactPerson } from "../../api/update/contactPerson";
import { addNewContactPerson } from "../../api/insert/contactPerson";

export default function ContactModal({ open, id, setOpen }) {
    const [showModal, setShowModal] = React.useState(open);
    const [data, setData] = useState([]);
    useEffect(() => {
        setShowModal(open);
    }, [open]);


    useEffect(() => {
        async function getter() {
            const data = await getContactOrgId(id);
            if (data !== undefined)
                setData(data.data.data);
            console.log("res____________", data)
        }
        getter();

    }, [id]);
    // State to manage pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of items per page
    async function getNew(id) {
        const data = await getContactOrgId(id);
        if (data !== undefined)
            setData(data.data.data);
        console.log("res____________", data)
    }

    // Logic to calculate pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // Function to handle page change


    const [contactPerson, setContactPerson] = useState(
        {
            _id: "",
            name: "",
            designation: "",
            email: "",
            phone: "",
            organizationId: id
        }
    );


    const [validation, setValidation] = useState({
        name: false,
        designation: false,
        email: false,
        phone: false
    })

    const nameInputRef = useRef();
    const designationInputRef = useRef();
    const phoneInputRef = useRef();
    const emailInputRef = useRef();
    const [addContactEnable, setAddContactEnable] = useState(true);


    function setFormData(_id, name, designation, phone, email, organizationId) {
        console.log("ci : ", organizationId)
        nameInputRef.current.value = name;
        designationInputRef.current.value = designation;
        phoneInputRef.current.value = phone;
        emailInputRef.current.value = email;
        setAddContactEnable(false);
        setContactPerson({ _id, name, designation, email, phone });
    }





    function resetForm() {
        nameInputRef.current.value = "";
        designationInputRef.current.value = "";
        phoneInputRef.current.value = "";
        emailInputRef.current.value = "";
        setAddContactEnable(true);
    }




    function onChangeInputValue(e, value) {
        console.log(e.target.value);
        console.log(value);
        setContactPerson(prevState => ({
            ...prevState,
            [value]: e.target.value
        }));
    }


    function validateData() {
        var validation = true;
        if (contactPerson['name'] === "") {
            console.log("validation", validation)
            setValidation(prevState => ({
                ...prevState,
                name: true
            }))
            validation = false;

        }
        else {
            setValidation(prevState => ({
                ...prevState,
                name: false
            }))
        }
        if (contactPerson['designation'] === "") {
            setValidation(prevState => ({
                ...prevState,
                designation: true
            }))
            validation = false;
        }
        else {
            setValidation(prevState => ({
                ...prevState,
                designation: false
            }))
        }


        if (contactPerson['phone'] === "") {
            setValidation(prevState => ({
                ...prevState,
                phone: true
            }));
            validation = false;
        }
        else {
            setValidation(prevState => ({
                ...prevState,
                phone: false
            }))
        }

        if (contactPerson['email'] === "") {
            setValidation(prevState => ({
                ...prevState,
                email: true
            }));
            validation = false;
        }
        else {
            setValidation(prevState => ({
                ...prevState,
                email: false
            }))
        }
        return validation;
    }


    async function updateContact() {
        const isValidate = validateData();
        if (isValidate) {
            const response = await updateContactPerson(contactPerson);
            const resData = response.data;
            console.log(resData)
            if (resData.success) {
                getNew();
                setShowModal(false);
                resetForm();
            }

        }
        else {
            console.log("validation error")
        }

    }


    async function addPerson() {
        const response = await addNewContactPerson(contactPerson, contactPerson.organizationId);
        console.log(response.data);
        const isValidate = validateData();
        if (isValidate) {
            if (response.data) {
                getNew();
                setShowModal(false);
                resetForm();
            }
        }
    }

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <>

            {showModal ? (
                <>

                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-full sm:w-auto my-1 mx-auto max-w-3xl">
                            {/* content */}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/* header */}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-2xl sm:text-3xl font-semibold">Contact Details</h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                    </button>
                                </div>
                                {/* body */}

                                <div className="relative p-6 flex-auto">
                                    <p className="my-1 text-blueGray-500 text-base sm:text-lg leading-relaxed">
                                        <div className="overflow-x-auto">
                                            <div className="p-1.5 w-full inline-block align-middle">
                                                <div className="overflow-hidden border rounded-lg">
                                                    <form className="w-full m-1">
                                                        <div className="flex flex-wrap mb-6">
                                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                                    Name
                                                                </label>
                                                                <input
                                                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${validation.name && "border-red-500"}`}
                                                                    id="grid-first-name"
                                                                    type="text"
                                                                    placeholder="Jane"
                                                                    ref={nameInputRef}
                                                                    onChange={(e) => { onChangeInputValue(e, "name") }}
                                                                />
                                                                {validation.name && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}

                                                            </div>
                                                        </div>

                                                        <div className="flex flex-wrap mb-6">
                                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-designation">
                                                                    Designation
                                                                </label>

                                                                <input
                                                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${validation.designation && "border-red-500"}`}
                                                                    id="grid-designation"
                                                                    type="text"
                                                                    placeholder="Jane"
                                                                    ref={designationInputRef}
                                                                    onChange={(e) => onChangeInputValue(e, "designation")}
                                                                />
                                                                {validation.designation && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-wrap mb-2">
                                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-phone">
                                                                    Phone
                                                                </label>
                                                                <input
                                                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${validation.phone && "border-red-500"}`}
                                                                    id="grid-phone"
                                                                    type="number"
                                                                    placeholder="Phone Number"
                                                                    ref={phoneInputRef}
                                                                    onChange={(e) => onChangeInputValue(e, "phone")}
                                                                />
                                                                {validation.phone && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
                                                            </div>
                                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    className={`appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${validation.email && "border-red-500"}`}
                                                                    id="grid-email"
                                                                    type="text"
                                                                    placeholder="email"
                                                                    ref={emailInputRef}
                                                                    onChange={(e) => onChangeInputValue(e, "email")}
                                                                />
                                                                {validation.email && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
                                                            </div>
                                                        </div>
                                                        {addContactEnable ? (
                                                            <div className="flex flex-wrap mb-2 m-4">
                                                                <button
                                                                    className="px-3 mb-6 md:mb-0 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { addPerson() }}
                                                                >
                                                                    Add contact
                                                                </button>

                                                            </div>

                                                        ) : (
                                                            <div className="flex flex-wrap mb-2 m-4">
                                                                <button
                                                                    className="px-3 mb-6 md:mb-0 bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { updateContact(); }}
                                                                >
                                                                    Update Contact
                                                                </button>
                                                                <button
                                                                    className="px-3 mb-6 md:mb-0 bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                                    type="button"
                                                                    onClick={() => { resetForm() }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        )}



                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                <div className="relative p-5 flex-auto">
                                    <p className="text-blueGray-500 text-base sm:text-lg leading-relaxed">
                                        <div className="flex flex-col">
                                            <div className="overflow-x-auto">
                                                <div className="p-1.5 w-full inline-block align-middle">
                                                    <div className="overflow-hidden border rounded-lg">
                                                        <div className="overflow-x-auto">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead className="bg-gray-50">
                                                                    <tr>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Name</th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Email</th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Phone</th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">Designation</th>
                                                                        <th scope="col" className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase">Edit</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="divide-y divide-gray-200">
                                                                    {currentItems.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.name}</td>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.email}</td>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.phone}</td>
                                                                            <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">{item.designation}</td>
                                                                            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                                                <div
                                                                                    onClick={() => { setFormData(item._id, item.name, item.designation, item.phone, item.email, item.organizationId._id) }}
                                                                                    className="text-green-500 hover:text-green-700"
                                                                                    href="#"
                                                                                >
                                                                                    Edit
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            {/* Pagination controls */}
                                                            <div className="flex justify-end mt-4">
                                                                <nav className="block">
                                                                    <ul className="flex pl-0 list-none rounded my-2">
                                                                        {/* Previous button */}
                                                                        <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 cursor-pointer hover:bg-gray-200">
                                                                            <a
                                                                                className="block"
                                                                                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
                                                                            >
                                                                                Previous
                                                                            </a>
                                                                        </li>
                                                                        {/* Page numbers */}
                                                                        {Array.from({ length: Math.ceil(data.length / itemsPerPage) }, (_, index) => (
                                                                            <li
                                                                                key={index}
                                                                                className={`relative block py-2 px-3 leading-tight border border-gray-300 ${Number(currentPage) === Number(index + 1) ? 'bg-white' : 'bg-gray-300'
                                                                                    } cursor-pointer`}
                                                                            >
                                                                                <a className="block" onClick={() => paginate(index + 1)}>
                                                                                    {index + 1}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                        {/* Next button */}
                                                                        <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-l-0 cursor-pointer hover:bg-gray-200">
                                                                            <a
                                                                                className="block"
                                                                                onClick={() =>
                                                                                    setCurrentPage(
                                                                                        currentPage < Math.ceil(data.length / itemsPerPage)
                                                                                            ? currentPage + 1
                                                                                            : currentPage
                                                                                    )
                                                                                }
                                                                            >
                                                                                Next
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                {/* footer */}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => { setShowModal(false); setOpen(false) }}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>

            ) : null}
        </>
    );
}
