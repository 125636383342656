import axios from "axios";
import { urlLocal } from "../../url/Url";


const url = urlLocal();
export async function  getUser(){
    try { 
        const response = await axios.post(`${url}/api/associates/employee/get/all` , {

        } , {headers :  {
            Authorization :  localStorage.getItem("token")
        }});
        return response ; 
    }
    catch (error){
        console.log(error);
    }
}

export const getTheEmployeeById =async(empId)=>{
    try{
        const employee = await axios.post(`${url}/api/admin/employeeDetailsById/${empId}`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return employee;
    }
    catch(error){
        console.error(error);
        return error;
    }
}