
// import React, { useState, useEffect } from 'react';
// import { fetchOrganizationTypes, fetchOrganizationsByType, fetchVisitDetails } from '../api/get/VisitStatisticsapi';

// const VisitStatistics = () => {
//   const [organizationTypes, setOrganizationTypes] = useState([]);
//   const [organizations, setOrganizations] = useState([]);
//   const [selectedOrganizationType, setSelectedOrganizationType] = useState('');
//   const [selectedOrganization, setSelectedOrganization] = useState('');
//   const [visitData, setVisitData] = useState(null);

//   useEffect(() => {
//     const fetchTypes = async () => {
//       try {
//         const types = await fetchOrganizationTypes();
//         setOrganizationTypes(types);
//       } catch (error) {
//         console.error('Error fetching organization types:', error);
//       }
//     };
//     fetchTypes();
//   }, []);

//   useEffect(() => {
//     const fetchOrgs = async () => {
//       if (selectedOrganizationType) {
//         try {
//           const orgs = await fetchOrganizationsByType(selectedOrganizationType);
//           setOrganizations(orgs);
          
//         } catch (error) {
//           console.error('Error fetching organizations:', error);
//         }
//       }
//     };
//     fetchOrgs();
//   }, [selectedOrganizationType]);

//   const fetchVisitData = () => {
//     if (selectedOrganizationType && selectedOrganization) {
//       fetchVisitDetails(selectedOrganizationType, selectedOrganization)
//         .then(data => {
//           setVisitData(data);
//           console.log(data)
//         })
//         .catch(error => console.error('Error fetching visit details:', error));
//     } else {
//       console.error('Please select both organization type and organization before fetching visit data');
//     }
//   };

//   const groupVisitsByDate = () => {
//     const groupedVisits = {};
//     if (visitData) {
//       visitData.data.visits.forEach(visit => {
//         const visitDate = new Date(visit.visitDateTime).toDateString();
//         if (!groupedVisits[visitDate]) {
//           groupedVisits[visitDate] = [];
//         }
//         groupedVisits[visitDate].push(visit);
//       });
//     }
//     return groupedVisits;
//   };

//   const getOrganizationContactPersons = (organizationId) => {
//     return visitData ? visitData.data.contactPersons.filter(contact => contact.organizationId === organizationId) : [];
//   };

//   let lastVisitDate = null;
//   let alternateSide = false;

//   return (
//     <div className="rounded-lg bg-gradient-to-tr from-gray-200 to-gray-50 p-6 shadow-lg">
//       <h2 className="text-lg font-semibold mb-4 text-blue-800">Milestones</h2>
//       <div className="mb-4 flex flex-col md:flex-row items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0">
//         <select
//           className="bg-gray-200 text-black px-4 py-2 rounded-md w-full md:w-auto"
//           value={selectedOrganizationType}
//           onChange={(e) => setSelectedOrganizationType(e.target.value)}
//         >
//           <option value="">Select Organization Type</option>
//           {organizationTypes.map(type => (
//             <option key={type._id} value={type.name}>{type.name}</option>
//           ))}
//         </select>
//         <select
//           className="bg-gray-200 text-black px-4 py-2 rounded-md w-full md:w-auto"
//           value={selectedOrganization}
//           onChange={(e) => setSelectedOrganization(e.target.value)}
//         >
//           <option value="">Select Organization</option>
//           {organizations.map(org => (
//             <option key={org._id} value={org.organizationName}>{org.organizationName}</option>
//           ))}
//         </select>
//         <button
//           className="bg-blue-500 text-white px-4 py-2 rounded-md w-full md:w-auto"
//           onClick={fetchVisitData}
//         >
//           Go
//         </button>
//       </div>
//       {visitData && (
//         <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5  before:bg-black">
//           {Object.entries(groupVisitsByDate()).map(([date, visits], index) => (
//             <div key={index}>
//               {/* {date} */}
//               {visits.map((visit, idx) => {
//                 const visitDate = new Date(visit.visitDateTime).toDateString();
//                 if (visitDate !== lastVisitDate) {
//                   alternateSide = !alternateSide;
//                 }
//                 lastVisitDate = visitDate;

//                 const visitIconColor = idx === 0 ? 'fill-emerald-500' : 'fill-slate-300';
//                 const cardContent = (
//                   <div className="bg-white p-4 rounded border border-slate-200 text-slate-500 shadow ml-14 md:ml-44 w-3/4">
//                     <div className="flex items-center justify-between space-x-2 mb-1">
//                       <div className="font-bold text-slate-900">{visit.purpose}</div>
//                       <time className="font-caveat p-2 font-l text-indigo-500">{visitDate.trim}</time>
//                     </div>
//                     <div>{visit.additionalNotes || 'No additional notes available.'}</div>
//                     <div className="mt-2">
//                       <h4 className="font-semibold text-gray-700">Contact Persons:</h4>
//                       <div className="mt-1 text-sm text-gray-600">
//                         <p><strong>Name:</strong> {visit.contactPersonId.name}</p>
//                         <p><strong>Designation:</strong> {visit.contactPersonId.designation}</p>
//                         <p><strong>Phone:</strong> {visit.contactPersonId.phone}</p>
//                         <p><strong>Email:</strong> {visit.contactPersonId.email}</p>
//                       </div>
//                     </div>
//                   </div>
//                 );

//                 return (
//                   <div key={visit._id} className={`relative ${alternateSide ? 'md:flex-row-reverse' : ''}`}>
//                     <div className="md:flex items-center md:space-x-4 mb-3">
//                       <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
//                         <div className="flex items-center justify-center w-10 h-10 rounded-full bg-emerald-500 shadow md:order-1">
//                           <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
//                             <path fill-rule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
//                           </svg>
//                         </div>
//                         <time className="font-caveat font-medium text-xl text-indigo-500 md:w-28">{visitDate}</time>
//                       </div>
//                       <div className="text-slate-500 ml-14">
//                         <span className="text-slate-900 font-bold">{visit.visitorName}</span> 
//                       </div>
//                     </div>
//                     {cardContent}
//                   </div>
//                 );
//               })}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VisitStatistics;


// import React, { useState, useEffect } from 'react';
// import { fetchOrganizationTypes, fetchOrganizationsByType, fetchVisitDetails } from '../api/get/VisitStatisticsapi';
// import { CSVLink } from 'react-csv';

// const VisitStatistics = () => {
//   const [organizationTypes, setOrganizationTypes] = useState([]);
//   const [organizations, setOrganizations] = useState([]);
//   const [selectedOrganizationType, setSelectedOrganizationType] = useState('');
//   const [selectedOrganization, setSelectedOrganization] = useState('');
//   const [visitData, setVisitData] = useState(null);

//   useEffect(() => {
//     const fetchTypes = async () => {
//       try {
//         const types = await fetchOrganizationTypes();
//         setOrganizationTypes(types);
//       } catch (error) {
//         console.error('Error fetching organization types:', error);
//       }
//     };
//     fetchTypes();
//   }, []);

//   useEffect(() => {
//     const fetchOrgs = async () => {
//       if (selectedOrganizationType) {
//         try {
//           const orgs = await fetchOrganizationsByType(selectedOrganizationType);
//           setOrganizations(orgs);
//         } catch (error) {
//           console.error('Error fetching organizations:', error);
//         }
//       }
//     };
//     fetchOrgs();
//   }, [selectedOrganizationType]);

//   const fetchVisitData = () => {
//     if (selectedOrganizationType && selectedOrganization) {
//       fetchVisitDetails(selectedOrganizationType, selectedOrganization)
//         .then(data => {
//           setVisitData(data);
//           console.log(data);
//         })
//         .catch(error => console.error('Error fetching visit details:', error));
//     } else {
//       console.error('Please select both organization type and organization before fetching visit data');
//     }
//   };

//   const groupVisitsByDate = () => {
//     const groupedVisits = {};
//     if (visitData) {
//       visitData.data.visits.forEach(visit => {
//         const visitDate = new Date(visit.visitDateTime).toDateString();
//         if (!groupedVisits[visitDate]) {
//           groupedVisits[visitDate] = [];
//         }
//         groupedVisits[visitDate].push(visit);
//       });
//     }
//     return groupedVisits;
//   };

//   const prepareCsvData = () => {
//     if (!visitData) return [];
//     return visitData.data.visits.map(visit => ({
//       purpose: visit.purpose,
//       visitDateTime: new Date(visit.visitDateTime).toDateString(),
//       additionalNotes: visit.additionalNotes || 'No additional notes available.',
//       contactPersonName: visit.contactPersonId.name,
//       contactPersonDesignation: visit.contactPersonId.designation,
//       contactPersonPhone: visit.contactPersonId.phone,
//       contactPersonEmail: visit.contactPersonId.email,
//       employeeFullName: visit.employeeId.userId.fullName,
//       employeeDesignation: visit.employeeId.designationId.name
//     }));
//   };

//   let lastVisitDate = null;
//   let alternateSide = false;

//   return (
//     <div className="rounded-lg bg-gradient-to-tr from-gray-200 to-gray-50 p-6 shadow-lg">
//       <h2 className="text-lg font-semibold mb-4 text-blue-800">Milestones</h2>
//       <div className="mb-4 flex flex-col space-y-2">
//         <select
//           className="bg-gray-200 text-black px-4 py-2 rounded-md w-full"
//           value={selectedOrganizationType}
//           onChange={(e) => setSelectedOrganizationType(e.target.value)}
//         >
//           <option value="">Select Organization Type</option>
//           {organizationTypes.map(type => (
//             <option key={type._id} value={type.name}>{type.name}</option>
//           ))}
//         </select>
//         <select
//           className="bg-gray-200 text-black px-4 py-2 rounded-md w-full"
//           value={selectedOrganization}
//           onChange={(e) => setSelectedOrganization(e.target.value)}
//         >
//           <option value="">Select Organization</option>
//           {organizations.map(org => (
//             <option key={org._id} value={org.organizationName}>{org.organizationName}</option>
//           ))}
//         </select>
//         <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
//           <button
//             className="bg-blue-500 text-white px-4 py-2 rounded-md w-full md:w-auto"
//             onClick={fetchVisitData}
//           >
//             Go
//           </button>
//           {visitData && (
//             <CSVLink
//               data={prepareCsvData()}
//               filename={'visit_data.csv'}
//               className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded w-full md:w-auto"
//               target="_blank"
//             >
//               Export CSV
//             </CSVLink>
//           )}
//         </div>
//       </div>
//       {visitData && (
//         <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5  before:bg-black">
//           {Object.entries(groupVisitsByDate()).map(([date, visits], index) => (
//             <div key={index}>
//               {visits.map((visit, idx) => {
//                 const visitDate = new Date(visit.visitDateTime).toDateString();
//                 if (visitDate !== lastVisitDate) {
//                   alternateSide = !alternateSide;
//                 }
//                 lastVisitDate = visitDate;

//                 const visitIconColor = idx === 0 ? 'fill-emerald-500' : 'fill-slate-300';
//                 const cardContent = (
//                   <div className="bg-white p-4 rounded border border-slate-200 text-slate-500 shadow ml-14 md:ml-44 w-3/4">
//                     <div className="flex items-center justify-between space-x-2 mb-1">
//                       <div className="font-bold text-slate-900">{visit.purpose}</div>
//                       <time className="font-caveat p-2 font-l text-indigo-500">{visitDate.trim}</time>
//                     </div>
//                     <div>{visit.additionalNotes || 'No additional notes available.'}</div>
//                     <div className="mt-2">
//                       <h4 className="font-semibold text-gray-700">Contact Person:</h4>
//                       <div className="mt-1 text-sm text-gray-600">
//                         <p><strong>Name:</strong> {visit.contactPersonId.name}</p>
//                         <p><strong>Designation:</strong> {visit.contactPersonId.designation}</p>
//                         <p><strong>Phone:</strong> {visit.contactPersonId.phone}</p>
//                         <p><strong>Email:</strong> {visit.contactPersonId.email}</p>
//                       </div>
//                     </div>
//                     <div className="mt-2">
//                       <h4 className="font-semibold text-gray-700">Employee Details:</h4>
//                       <div className="mt-1 text-sm text-gray-600">
//                         <p><strong>Name:</strong> {visit.employeeId.userId.fullName}</p>
//                         <p><strong>Designation:</strong> {visit.employeeId.designationId.name}</p>
//                       </div>
//                     </div>
//                   </div>
//                 );

//                 return (
//                   <div key={visit._id} className={`relative ${alternateSide ? 'md:flex-row-reverse' : ''}`}>
//                     <div className="md:flex items-center md:space-x-4 mb-3">
//                       <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
//                         <div className="flex items-center justify-center w-10 h-10 rounded-full bg-emerald-500 shadow md:order-1">
//                           <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
//                             <path fillRule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
//                           </svg>
//                         </div>
//                         <time className="font-caveat font-medium text-xl text-indigo-500 md:w-28">{visitDate}</time>
//                       </div>
//                       <div className="text-slate-500 ml-14">
//                         <span className="text-slate-900 font-bold">{visit.visitorName}</span> 
//                       </div>
//                     </div>
//                     {cardContent}
//                   </div>
//                 );
//               })}
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VisitStatistics;


import React, { useState, useEffect } from 'react';
import { fetchOrganizationTypes, fetchOrganizationsByType, fetchVisitDetails } from '../api/get/VisitStatisticsapi';
import { CSVLink } from 'react-csv';

const VisitStatistics = () => {
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizationType, setSelectedOrganizationType] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [visitData, setVisitData] = useState(null);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const types = await fetchOrganizationTypes();
        setOrganizationTypes(types);
      } catch (error) {
        console.error('Error fetching organization types:', error);
      }
    };
    fetchTypes();
  }, []);

  useEffect(() => {
    const fetchOrgs = async () => {
      if (selectedOrganizationType) {
        try {
          const orgs = await fetchOrganizationsByType(selectedOrganizationType);
          setOrganizations(orgs);
        } catch (error) {
          console.error('Error fetching organizations:', error);
        }
      }
    };
    fetchOrgs();
  }, [selectedOrganizationType]);

  const fetchVisitData = () => {
    if (selectedOrganizationType && selectedOrganization) {
      fetchVisitDetails(selectedOrganizationType, selectedOrganization)
        .then(data => {
          setVisitData(data);
          console.log(data);
        })
        .catch(error => console.error('Error fetching visit details:', error));
    } else {
      console.error('Please select both organization type and organization before fetching visit data');
    }
  };

  const groupVisitsByDate = () => {
    const groupedVisits = {};
    if (visitData) {
      visitData.data.visits.forEach(visit => {
        const visitDate = new Date(visit.visitDateTime).toDateString();
        if (!groupedVisits[visitDate]) {
          groupedVisits[visitDate] = [];
        }
        groupedVisits[visitDate].push(visit);
      });
    }
    return groupedVisits;
  };

  const prepareCsvData = () => {
    if (!visitData) return [];
    return visitData.data.visits.map(visit => ({
      purpose: visit.purpose,
      visitDateTime: new Date(visit.visitDateTime).toDateString(),
      additionalNotes: visit.additionalNotes || 'No additional notes available.',
      contactPersonName: visit.contactPersonId.name,
      contactPersonDesignation: visit.contactPersonId.designation,
      contactPersonPhone: visit.contactPersonId.phone,
      contactPersonEmail: visit.contactPersonId.email,
      employeeFullName: visit.employeeId.userId.fullName,
      employeeDesignation: visit.employeeId.designationId.name
    }));
  };

  let lastVisitDate = null;
  let alternateSide = false;

  return (
    <div className="rounded-lg bg-gradient-to-tr from-gray-200 to-gray-50 p-6 shadow-lg">
      <h2 className="text-lg font-semibold mb-4 text-blue-800">Milestones</h2>
      <div className="mb-4 flex flex-col space-y-2">
        <select
          className="bg-gray-200 text-black px-4 py-2 rounded-md w-full"
          value={selectedOrganizationType}
          onChange={(e) => setSelectedOrganizationType(e.target.value)}
        >
          <option value="">Select Organization Type</option>
          {organizationTypes.map(type => (
            <option key={type._id} value={type.name}>{type.name}</option>
          ))}
        </select>
        <select
          className="bg-gray-200 text-black px-4 py-2 rounded-md w-full"
          value={selectedOrganization}
          onChange={(e) => setSelectedOrganization(e.target.value)}
        >
          <option value="">Select Organization</option>
          {organizations.map(org => (
            <option key={org._id} value={org.organizationName}>{org.organizationName}</option>
          ))}
        </select>
        <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full md:w-auto"
            onClick={fetchVisitData}
          >
            Go
          </button>
          {visitData && (
            <CSVLink
              data={prepareCsvData()}
              filename={'visit_data.csv'}
              className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded w-full md:w-auto"
              target="_blank"
            >
              Export CSV
            </CSVLink>
          )}
        </div>
      </div>
      {visitData ? (
        Object.keys(groupVisitsByDate()).length > 0 ? (
          <div className="space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:ml-[8.75rem] md:before:translate-x-0 before:h-full before:w-0.5 before:bg-black">
            {Object.entries(groupVisitsByDate()).map(([date, visits], index) => (
              <div key={index}>
                {visits.map((visit, idx) => {
                  const visitDate = new Date(visit.visitDateTime).toDateString();
                  if (visitDate !== lastVisitDate) {
                    alternateSide = !alternateSide;
                  }
                  lastVisitDate = visitDate;

                  const visitIconColor = idx === 0 ? 'fill-emerald-500' : 'fill-slate-300';
                  const cardContent = (
                    <div className="bg-white p-4 rounded border border-slate-200 text-slate-500 shadow ml-14 md:ml-44 w-3/4">
                      <div className="flex items-center justify-between space-x-2 mb-1">
                        <div className="font-bold text-slate-900">{visit.purpose}</div>
                        <time className="font-caveat p-2 font-l text-indigo-500">{visitDate.trim()}</time>
                      </div>
                      <div>{visit.additionalNotes || 'No additional notes available.'}</div>
                      <div className="mt-2">
                        <h4 className="font-semibold text-gray-700">Contact Person:</h4>
                        <div className="mt-1 text-sm text-gray-600">
                          <p><strong>Name:</strong> {visit.contactPersonId.name}</p>
                          <p><strong>Designation:</strong> {visit.contactPersonId.designation}</p>
                          <p><strong>Phone:</strong> {visit.contactPersonId.phone}</p>
                          <p><strong>Email:</strong> {visit.contactPersonId.email}</p>
                        </div>
                      </div>
                      <div className="mt-2">
                        <h4 className="font-semibold text-gray-700">Employee Details:</h4>
                        <div className="mt-1 text-sm text-gray-600">
                          <p><strong>Name:</strong> {visit.employeeId.userId.fullName}</p>
                          <p><strong>Designation:</strong> {visit.employeeId.designationId.name}</p>
                        </div>
                      </div>
                    </div>
                  );

                  return (
                    <div key={visit._id} className={`relative ${alternateSide ? 'md:flex-row-reverse' : ''}`}>
                      <div className="md:flex items-center md:space-x-4 mb-3">
                        <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse">
                          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-emerald-500 shadow md:order-1">
                            <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
                              <path fillRule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
                            </svg>
                          </div>
                          <time className="font-caveat font-medium text-xl text-indigo-500 md:w-28">{visitDate}</time>
                        </div>
                        <div className="text-slate-500 ml-14">
                          <span className="text-slate-900 font-bold">{visit.visitorName}</span>
                        </div>
                      </div>
                      {cardContent}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500 mt-8">No milestones</div>
        )
      ) : null}
    </div>
  );
};

export default VisitStatistics;
