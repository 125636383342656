import React, { useState } from 'react';
import Navbar from '../components/Inavbar';
import ISidebar from '../components/Isidebar';
import MilestoneInteraction from '../components/milestoncomponent';


const ILayout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className="flex flex-col min-h-screen">
            <Navbar title={'User Interaction'} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} />
            <div className="flex flex-1">
                {/* <Sidebar isOpen={sidebarOpen} /> */}
                <main className={`flex-1 p-5 bg-gray-100 transition-all duration-300 ${sidebarOpen ? 'ml-64' : 'ml-0'}`}>
                    <div className="flex-1 flex flex-col">
                        <div className="flex-1 overflow-auto justify-end">
                        {/* <MilestoneInteraction/>  */}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default ILayout;
