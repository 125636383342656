import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

// update the user data based on the id
export const updateUserDetails =(employeeData)=>{
    try{
        const updateUser = axios.post(`${url}/api/admin/user/update`,{
            "UserId" : employeeData.id ,
            "username" : employeeData.userid,
            "fullName" :  employeeData.fullName ,
            "email" : employeeData.email,
            "role"  : employeeData.role ,
            "designationId" :employeeData.designationId,
            "employeeId" : employeeData.employeeId,
            "parentId" : employeeData.parent
        },{
            headers:{
                Authorization: localStorage.getItem('token')
            }
        });
        return updateUser;
    }
    catch(error){
        console.log(error);
        return error;
    }
}


export const removeEmployeeFromList = async(empId)=>{
    try{
        const removeEmp = await axios.post(`${url}/api/admin/employee/remove/${empId}`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        })
        return removeEmp
    }
    catch(error){
        console.error(error);
        return error;
    }
}

// update the organization type
export const updateTheOrganizationType = async(newOrgName,orgTypeId)=>{
    try{
        const update = await axios.post(`${url}/api/admin/orgType/update/${orgTypeId}`,{
            name:newOrgName
        },{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        })
        return update;
    }
    catch(error){
        console.error(error);
        return error;
    }
}

// remove the orgType from the list
export const removeTheOrgType =async(orgTypeId)=>{
    try{
        const remove = await axios.post(`${url}/api/admin/orgType/remove/${orgTypeId}`,{},{
            headers:{
                Authorization:localStorage.getItem('token')
            }
        });
        return remove;
    }
    catch(error){
        console.error(error);
        return error;
    }
}