import axios from "axios";
import { urlLocal } from "../../url/Url";

const url = urlLocal();

// add new Contact person
export const addNewContactPerson = async(contactPerson,orgId)=>{
    try{
        
        const newPerson =  await axios.post(`${url}/api/associates/contactPerson/create`,{
            organizationId:orgId,
            name:contactPerson.name,
            designation:contactPerson.designation,
            phone:contactPerson.phone,
            email:contactPerson.email
        },{
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
        return newPerson;
    }
    catch(error){
        console.error(error);
        return error;
    }
}