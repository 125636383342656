import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAdmin } from '../api/get/checkAdmin';

const Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Initially closed
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

    const navigate = useNavigate();

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 640);
        if (window.innerWidth > 640) {
            setIsSidebarOpen(false); // Close the sidebar on larger screens
        }
    };
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', handleResize);


        async function isAdminSetter() {
            setIsAdmin(await checkAdmin());
        }

        isAdminSetter();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
// handle navigation
const handleNavigation=(path)=>{
    navigate(path)
}
    return (
        <>
            {isSmallScreen && (
                <button
                    onClick={toggleSidebar}
                    className="fixed top-2 left-2 z-50 p-2 bg-gray-700 text-white rounded-md focus:outline-none sm:hidden">
                    {!isSidebarOpen ?
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        :
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>

                    }

                </button>
            )}
            <aside
                id="default-sidebar"
                className={`fixed mt-[50px] sm:mt-0 sm:top-0 sm:left-0 z-40 h-screen transition-transform transform ${isSmallScreen && !isSidebarOpen ? '-translate-x-full' : 'translate-x-0'
                    } w-[240px] bg-gray-50 dark:bg-gray-800 sm:translate-x-0`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 pt-10 py-4 overflow-y-auto flex flex-col justify-between">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <a
                                href="/homepage"
                                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg
                                    className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 22 21"
                                >
                                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                                </svg>
                                <span className="ms-3">Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="/visitlogs"
                                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg
                                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 18 18"
                                >
                                    <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                </svg>

                                <span className="flex-1 ms-3 whitespace-nowrap">Visit logs</span>
                            </a>
                        </li>
                        {isAdmin && (
                            <>
                                <li>
                                    <div
                                        onClick={()=>{handleNavigation('/adminInstitution')}}
                                        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                    >
                                        {/* <svg
                                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 18 18"
                                        >
                                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                        </svg> */}
                                        <img src={require('../assets/Institution.png')} className='w-[25px] h-[25px]' />

                                        <span className="flex-1 ms-3 whitespace-nowrap">Manage Institution</span>
                                    </div>
                                </li>

                                <li>
                                    <div
                                        onClick={()=>{handleNavigation('/adminEmployeeList')}}
                                        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                    >
                                        <svg
                                             className="flex-shrink-0 w-5 h-5 text-gray-700 transition duration-75 dark:text-gray-700 group-hover:text-gray-900 dark:group-hover:text-white"
                                            viewBox="0 0 24 24"
                                            strokeWidth="3"
                                            stroke="currentColor"
                                            fill="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            >
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <circle cx="9" cy="7" r="4" />
                                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                            </svg>

                                        <span className="flex-1 ms-3 whitespace-nowrap">Manage Users </span>
                                    </div>
                                </li>

                                <li>
                                    <div
                                        onClick={()=>{handleNavigation('/orgType')}}
                                        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                    >
                                        {/* <svg
                                            className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 18 18"
                                        >
                                            <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                                        </svg> */}
                                        <img src={require('../assets/IntType.png')} className='w-[25px] h-[25px]' />

                                        <span className="flex-1 ms-3 whitespace-nowrap">Organization Types </span>
                                    </div>
                                </li>
                            </>
                        )}


                    </ul>
                    {/* <ul className="space-y-2 font-medium">
                        <li>
                            <div
                                onClick={handleSignOut}
                                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-logout">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                                <path d="M9 12h12l-3 -3" />
                                <path d="M18 15l3 -3" />
                            </svg>
                                {!isSmallScreen && <span className="ms-3">Sign Out</span>}
                            </div>
                        </li>
                    </ul> */}
                </div>
            </aside>
        </>
    );
};

export default Sidebar;
